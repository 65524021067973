import React from 'react';
import { Route } from 'react-router';

import MaintenanceInternal from './MaintenanceInternal';

const App = () => (
  <>
    <div className="container" style={{ paddingTop: 20 }}>
      <Route
        exact
        path="/maintenance/internal"
        component={MaintenanceInternal}
      />
    </div>
  </>
);

export default App;
