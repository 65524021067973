import React from 'react';
const MaintenanceInternal = () => (
  <div>
    <section>
      <div className="row">
        <div className="col-lg-7">
          <img
            src="images/guardian-logo.svg"
            className="img-responsive"
            alt="Guardian"
          />
          <h1>Maintenance work in progress - Internal</h1>
          <p>
            The site is not available at this time and will be back up as soon
            as possible.{' '}
          </p>
          <p>
            Thank you for your patience, we apologize for the inconvenience.{' '}
          </p>
          <p>
            For all general inquiries, you can{' '}
            <a href="mailto:corporate_inquiries@glic.com">email us</a>.
          </p>
        </div>
        <div className="col-lg-5 pt-3">
          <img
            className="img-header"
            src="images/GettyImages.jpg"
            alt="Two friends rock climbing"
          />
        </div>
      </div>
    </section>
  </div>
);

export default MaintenanceInternal;
